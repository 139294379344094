<template>
    <!--  内蒙古自治区工伤预防线上培训平台-->
    <LoginMongolia v-if="$judgeServerHostname()==1"></LoginMongolia>
    <!--  安知企培  -->
    <LoginQiye v-else-if="$judgeServerHostname()==2"></LoginQiye>
    <!--  安知人力资源服务平台  -->
    <HRLogin v-else-if="$judgeServerHostname()==3"></HRLogin>
    <!--  安知管理后台  -->
    <LoginCommon v-else></LoginCommon>
</template>

<script>
    import LoginCommon from "./LoginCommon.vue";
    import LoginMongolia from "./LoginMongolia.vue";
    import LoginQiye from "./LoginQiye.vue";
    import HRLogin from "../humanResources/HRLogin.vue";
    export default {
        name:"login",
        data(){
            return{
                isMongolia:null
            }
        },
        components:{
            LoginCommon,
            LoginQiye,
            LoginMongolia,
            HRLogin
        },
    }
</script>