<template>
  <div class="loginContainer">
    <TopBar topTitle="内蒙古自治区工伤预防线上培训平台"></TopBar>
    <div class="loginContent">
      <div class="loginInput-wrap">
        <div class="loginInput">
          <div class="inputbox">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="login_input"
            >
              <el-form-item prop="username">
                <img class="icon" src="../../assets/login_user.png" alt="">
                <el-input
                  v-model="ruleForm.username"
                  class="loginput"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入用户名"
                  @focus="
                    (e) => {
                      clear('username');
                    }
                  "
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <img class="icon" src="../../assets/login_password.png" alt="">
                <el-input
                  v-model.trim="ruleForm.password"
                  type="password"
                  class="loginput"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入密码"
                  @focus="
                    (e) => {
                      clear('password');
                    }
                  "
                >
                </el-input>
              </el-form-item>
              <el-form-item prop="code">
                <div class="flexcb code" style="width: 100%">
                    <img class="icon" src="../../assets/login_code.png" alt="">
                  <el-input
                    style="width: 181px"
                    class="flex1"
                    clearable
                    v-model="ruleForm.code"
                    placeholder="验证码"
                    :maxlength="50"
                    @keyup.enter.native="submit()"
                    @focus="
                      (e) => {
                        clear('code');
                      }
                    "
                  ></el-input>
                  <img
                    @click="getYzm"
                    :src="yzmInfo.imageBase64"
                    alt=""
                    class="codeImg"
                  />
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="loginButton-forget">
            <el-button type="text" @click="ForgetPassword()"
              >忘记密码?</el-button
            >
          </div>
        </div>
        <div class="loginButton">
          <el-button
            @click="submit()"
            >登录</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      title="获取验证码"
      :visible.sync="dialogVisiblemobile"
      width="30%"
      top="15%"
      :center="true"
      :before-close="doCloseMobile"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="120px"
        ref="editForm"
        :model="editForm"
        :rules="editrules"
      >
        <el-form-item label="验证码" prop="code">
          <el-input
            v-model="editForm.code"
            type="text"
            size="small"
            placeholder="请输入验证码"
          >
            <el-button
              slot="append"
              @click="getCode"
              :disabled="codeDisabled"
              size="small"
              >{{ codemsg }}</el-button
            >
          </el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="doCloseMobile()" class="bgc-bv">取消</el-button>
        <el-button class="bgc-bv" @click="editmobileSure('editForm')"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AES from "../../utils/aes";
import TopBar from "@/components/topToolbarSimple.vue";

export default {
  name: "loginCommon",
  components: {
    TopBar,
  },
  data() {
    //验证码校验
    let validateCode = (rule, value, callback) => {
      if (value.trim() != "") {
        if (value != this.verificationCode) {
          callback(new Error("请输入正确的验证码"));
        } else {
          callback();
        }
      } else {
        callback(new Error("请输入验证码"));
      }
    };
    return {
      version: process.env.VUE_APP_VERSION,
      // 公共方法
      ruleForm: {
        username: "",
        password: "",
        code: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
        // code: [
        //   {
        //     required: true,
        //     message: "请输入验证码",
        //     trigger: ["blur"],
        //   },
        //   { validator: validateCode, trigger: ["blur"] },
        // ],
      },
      //验证码
      verificationCode: this.createCode(),
      dialogVisiblemobile: false,
      editForm: {},
      codemsg: "获取验证码",
      auth_time: 180,
      codeDisabled: false,
      editrules: {
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur"],
          },
        ],
      },
      // 获取服务器的验证码
      yzmInfo: {
        imageBase64: "",
        key: "",
      },
      //创新引领数据
      inNovateData: [
        {
          icon: require("@/assets/loginIndex/icon18.png"),
          mainTitle: "精品内容",
          subTitle: "海量内容与资源协作共享<br/>北戴河内容制作基地",
          url: "/wrongAuthenticatePage/productionBase",
        },
        {
          icon: require("@/assets/loginIndex/icon19.png"),
          mainTitle: "全景运营",
          subTitle: "多维度运营体系<br/>数据化支撑精准服务",
          url: "/wrongAuthenticatePage/integratedMarketing",
        },
        {
          icon: require("@/assets/loginIndex/icon20.png"),
          mainTitle: "数字赋能",
          subTitle: "多种解决方案<br/>助力企业培训数字化转型",
          url: "/wrongAuthenticatePage/aboutShengrongAn",
        },
        {
          icon: require("@/assets/loginIndex/icon21.png"),
          mainTitle: "安全保障",
          subTitle: "国家信息安全等级保护<br/>三级认证",
          url: "/wrongAuthenticatePage/threeLevel",
        },
      ],
      bannerList: [],
      workList: [],
      courseList: [],
      active: 1,
      activeName: "",
    };
  },
  created: function () {
    this.getYzm();
    this.checkbrowser();
  },
  methods: {
    onTo(e) {
      if (!e.url) {
        return;
      }
      this.$router.push({
        path: e.url,
        query: {},
      });
    },
    clear(val) {
      const el = this.$refs["ruleForm"];
      el.clearValidate(val);
    },
    createCode() {
      //通过随机数生成验证码
      let code = "";
      let codeLength = 4; //验证码长度
      let random = new Array(
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z"
      );
      for (let i = 0; i < codeLength; i++) {
        let index = Math.floor(Math.random() * 36);
        code += random[index];
      }
      // return code;
      return code;
    },
    refreshCode() {
      /**
       * 生成验证码
       * @param
       */
      this.verificationCode = this.createCode();
    },
    // 获取验证码
    getYzm() {
      this.$post("/sys/captcha/get").then((res) => {
        this.yzmInfo.imageBase64 = res.data.imageBase64;
        this.yzmInfo.key = res.data.key;
        this.ruleForm.code = "";
      });
    },
    // 登录
    submit(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.ruleForm.username = this.ruleForm.username.replace(
            /^\s+|\s+$/g,
            ""
          );
          this.$post(
            "/sys/admin/validation",
            {
              password: this.ruleForm.password.trim(),
              username: this.ruleForm.username,
            },
            3000,
            false
          ).then((ret) => {
            if (ret.data.enable) {
              this.dialogVisiblemobile = true;
              this.editForm.editmobile = AES.decrypt(ret.data.mobile);
            } else {
              this.$post(
                "/login",
                {
                  password: this.ruleForm.password,
                  username: this.ruleForm.username,
                  captchaKey: this.yzmInfo.key,
                  captchaValue: this.ruleForm.code,
                },
                3000,
                false
              )
                .then((ret1) => {
                  this.cacheUserData(ret1);
                })
                .catch(() => {
                  this.$store.dispatch("handleDelAll");
                  this.getYzm();
                });
            }
          });
        } else {
          this.refreshCode();
        }
      });
    },
    //验证码倒计时
    getAuthCode() {
      if (this.authTimeTimer) {
        clearTimeout(this.authTimeTimer);
      }
      this.authTimeTimer = setTimeout(() => {
        this.auth_time -= 1;
        this.codemsg = this.auth_time + "s";
        this.codeDisabled = true;
        if (this.auth_time < 0) {
          this.codeDisabled = false;
          this.codemsg = "获取验证码";
          clearTimeout(this.authTimeTimer);
        } else {
          this.getAuthCode();
        }
      }, 1000);
    },
    getCode() {
      this.auth_time = 180;
      this.codeDisabled = false;
      this.$post("/sys/sms/login/captcha", { mobile: this.editForm.editmobile })
        .then((ret) => {
          this.getAuthCode();
          this.$confirm("验证码已发送至手机号上，请查收。", {
            confirmButonText: "确定",
            center: true,
            confirmButtonClass: "bgc-bv",
          });
        })
        .catch((err) => {
          return;
        });
    },
    editmobileSure(editForm) {
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          this.$post("/login", {
            password: this.ruleForm.password,
            username: this.ruleForm.username,
            captchaKey: this.yzmInfo.key,
            captchaValue: this.ruleForm.code,
            smsCode: this.editForm.code,
          })
            .then((ret) => {
              this.dialogVisiblemobile = false;
              this.codemsg = "获取验证码";
              this.codeDisabled = false;
              this.editForm = {};
              this.cacheUserData(ret);
            })
            .catch(() => {
              this.$store.dispatch("handleDelAll");
            });
        } else {
          return false;
        }
      });
    },
    doCloseMobile() {
      this.dialogVisiblemobile = false;
      this.$refs["editForm"].resetFields();
      this.editForm = {
        editmobile: "",
        code: "",
      };
      clearTimeout(this.authTimeTimer);
      this.codemsg = "获取验证码";
      this.codeDisabled = false;
    },
    // 缓存登录数据
    cacheUserData(ret) {
      const datas = ret.data || {};
      //   console.log(datas);
      this.roleId = datas.principal.roleId;
      // 缓存参数
      this.$store.dispatch("handleToken", datas.token || "");
      this.$store.dispatch("handleUser", {
        compId: datas.principal.compId || "",
        userId: datas.userId || "",
        fullname: datas.principal.fullname || "",
        roleId: datas.principal.roleId || "",
        passwordExpire: datas.principal.passwordExpire,
        trainTypeIdRange: datas.principal.trainTypeIdRange,
        createTime: datas.principal.createTime,
        tempAccount: datas.principal.tempAccount,
        videoIsMark: datas.principal.videoIsMark,
        areaId:datas.principal.areaId || "",
      });
      if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
        this.$router.push("/web/operate/change");
      } else {
        this.getMenu();
      }
    },
    getMenu() {
      this.$post("/sys/per/menu/userId", {}, 3000, false)
        .then((res) => {
          const data = res.data || [];
          this.setDictionary().then(() => {
            let url = (data.find((i) => i.name == "首页") || {}).url || null;
            if (this.roleId != "2" && url) {
              this.$router.push(url);
            } else {
              this.$router.push({
                path: data[0].url ? data[0].url : data[1].url,
              });
            }
          });
          this.$store.dispatch("handleMenu", data || []);
        })
        .catch(() => {
          this.$store.dispatch("handleDelAll");
        });
    },
    // 数据字典
    setDictionary() {
      return new Promise((resolve, reject) => {
        this.$post("/sys/dictionary/all", {}, 3000, false).then((ret) => {
          const retData = ret.data || [];
          let dictionaryArr = [];
          let dictionaryJson = {};
          retData.forEach((item) => {
            if (!dictionaryArr.includes(item.dictKey)) {
              dictionaryArr.push(item.dictKey);
              dictionaryJson[item.dictKey] = {};
            }
            dictionaryJson[item.dictKey][item.dictCode] = item.dictValue;
          });
          this.$store.dispatch("handleDictionary", dictionaryJson);
          resolve();
        });
      });
    },

    checkbrowser() {
      if (navigator.userAgent.indexOf("Edge") >= 0) {
        //微软edge
        alert(
          "抱歉！暂时还无法兼容您当前的浏览器！为了能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      } else if (
        navigator.userAgent.indexOf("Chrome") >= 0 &&
        navigator.userAgent.indexOf("WebKit") >= 0
      ) {
        //谷歌内核
      } else if (navigator.userAgent.indexOf("AppleWebKit") >= 0) {
        //mac谷歌内核
      } else {
        alert(
          "抱歉！暂时还无法兼容您当前的浏览器！为了您能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      }
    },
    //忘记密码
    ForgetPassword() {
      this.$router.push("/web/ForgetPassword");
    },
  },
};
</script>

<style lang="less" scoped>
.loginContainer {
  .loginContent {
    height: 100vh;
    background-image: url("../../assets/loginMongolia_banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 20.625rem;
    .loginInput-wrap{
        display: flex;
        flex-direction: column;
        width: 322px;
        height: 322px;
        box-shadow: 0px 0px 23px 0px rgba(57,126,255,0.1);
        border-radius: 9px;
        background-color: white;
        padding: 30px;
        .el-form{
            .el-form-item{
                position: relative;
                border-bottom: 1px solid #ccc;
                .icon{
                    display: block;
                    height: 20px;
                    position: absolute;
                    top: 10px;
                    left: 2px;
                    z-index: 1;
                }
                .codeImg{
                    cursor: pointer;
                }
                /deep/.el-input .el-input__inner{
                    padding-left:40px;
                    border: none;
                }
            }
        }
        .loginButton-forget{
            padding-left:2px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #397EFF;
        }
        .loginButton{
            .el-button{
                width: 100%;
                height: 45px;
                background: #397EFF;
                border-radius: 5px;
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
  }
}
</style>