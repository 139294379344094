<template>
  <!-- 登录 - /login -->
  <div class="login">
    <div class="login_1">
      <img src="@/assets/login-qiye-img2.png" alt="" />
    </div>
    <div class="login_2_wrap">
      <div class="login_2_bg">
        <img src="@/assets/login-qiye-img.png" alt="" />
      </div>
      <div class="login_2">
        <div class="wrap">
          <div class="tabs">
            <div
              class="tab"
              :class="loginType === '2' ? 'active' : ''"
              @click="loginHanle('2')"
            >
              账号密码登录
            </div>
            <div
              class="tab"
              :class="loginType === '1' ? 'active' : ''"
              @click="loginHanle('1')"
            >
              验证码登录
            </div>
          </div>
          <div class="loginForm">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="demo-ruleForm"
              >
              <el-form-item prop="mobilePhone" v-if="loginType == 1"  key="mobilePhone">
                <el-input
                  v-model="ruleForm.mobilePhone"
                  class="loginput"
                  clearable
                  maxlength="11"
                  auto-complete="off"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  @keyup.enter.native="submit()"
                  placeholder="请输入您的手机号码"
                />
              </el-form-item>
              <el-form-item prop="username" v-if="loginType == 2"  key="username">
                <el-input
                  v-model="ruleForm.username"
                  class="loginput"
                  clearable
                  auto-complete="off"
                  @keyup.enter.native="submit()"
                  placeholder="请输入您的用户名"
                />
              </el-form-item>
              <el-form-item prop="password" v-if="loginType == 2" key="password">
                <el-input
                  v-model="ruleForm.password"
                  class="loginput"
                  type="password"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入您的密码"
                />
              </el-form-item>
              <el-form-item prop="code" v-if="loginType == 2"  key="code">
                <div class="flexcb code" style="width: 100%">
                  <el-input
                    style="width: 181px"
                    class="flex1"
                    clearable
                    v-model="ruleForm.code"
                    placeholder="验证码"
                    :maxlength="50"
                    @keyup.enter.native="submit()"
                    @focus="
                      (e) => {
                        clear('code');
                      }
                    "
                  ></el-input>
                  <img
                    @click="getYzm"
                    :src="yzmInfo.imageBase64"
                    alt=""
                    class="codeImg"
                  />
                </div>
              </el-form-item>
              <el-form-item prop="msgeCode" class="yzm" v-if="loginType == 1" key="msgeCode">
                <el-input
                  v-model="ruleForm.msgeCode"
                  type="text"
                  onkeyup="value=value.replace(/[^\d]/g,'')"
                  auto-complete="off"
                  class="loginput"
                  clearable
                  @keyup.enter.native="submit()"
                  placeholder="请输入验证码"
                />
                <el-button v-if="sendShow" @click="getcheckCode()" type="text"
                  >获取验证码</el-button
                >
                <span v-else style="color: #66b1ff">{{ totalTime }}</span>
              </el-form-item>
              <el-button
                type="primary"
                size="large"
                round
                class="onSubmit"
                @click="submit()"
              >
                登 录
              </el-button>
            </el-form>
          </div>
        </div>
        <p>
          登录即代表您已同意
          <el-link type="primary" :underline="false" @click="jump(1)"
              >《服务协议》</el-link
            ><el-link type="primary" :underline="false" @click="jump(2)"
              >《隐私政策》</el-link
            >
        </p>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="800px"
      @close="handleClose"
    >
      <div>
        <iframe
          :src="src"
          frameborder="0"
          scrolling="auto"
          width="100%"
          height="500px"
        ></iframe>
      </div>
    </el-dialog>
    <div class="loginFoot">
      <div>安知企培平台</div>
      <div class="loginFoot_con">
        <span>
          {{ new Date().getFullYear() }} © 北京晟融信息技术有限公司
          <span
            @click="goToIp"
            style="cursor: pointer; font-size: 14px; font-family: 'PingFang SC'"
            >京ICP备19021711号 -2</span
          >
        </span>
        <div class="loginpadding">
          <el-link
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030034"
            target="_blank"
            :underline="false"
          >
            <img
              style="float: left; margin-top: 4px; margin-left: 20px"
              src="../../assets/gongan.png"
              alt=""
            />
            <span>京公网安备 11010802030034号</span>
          </el-link>
        </div>
        <span v-show="version">版本:{{ version }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import AES from "../../utils/aes";
import TopBar from "@/components/topToolbarSimple.vue";

export default {
  name: "loginCommon",
  components: {
    TopBar,
  },
  data() {
    return {
      // 登录方式
      loginType: "2",
      // 登录信息
      ruleForm: {
        username: "", // 用户名
        mobilePhone: "", // 手机号
        msgeCode: "", // 验证码
        password: "", // 密码
        code: "", // 验证码
      },
      // 校验
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: ["blur"],
          },
        ],
        mobilePhone: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: ["blur"],
          },
        ],
        msgeCode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur"],
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur"],
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: ["blur"],
          },
        ],
      },
      totalTime: 180,//验证码倒计时
      countInterval: null,//验证码定时器
      sendShow: true,//验证码按钮显示
      dialogVisible: false, //协议弹层
      src: "", //协议路径
      title: "", //协议表头

      // 获取服务器的验证码
      yzmInfo: {
        imageBase64: "",
        key: "",
      },
      version: process.env.VUE_APP_VERSION,
    };
  },
  created: function () {
    this.getYzm();
    this.checkbrowser();
  },
  beforeDestroy(){
    clearInterval(this.countInterval);
    this.countInterval = null
  },
  methods: {
    // 切换登录方式
    loginHanle(type) {
      if (this.loginType != type) {
        this.loginType = type;
        this.$refs["ruleForm"].clearValidate();
        this.ruleForm={
          username: "", // 用户名
          mobilePhone: "", // 手机号
          msgeCode: "", // 验证码
          password: "", // 密码
          code: "", // 验证码
        }
      }
    },
    // 获取验证码
    getcheckCode() {
      this.$refs.ruleForm.validateField("mobilePhone", (valid) => {
        if (!valid) {
          this.$post("/sys/sms/login/captcha", {
            mobile: this.ruleForm.mobilePhone,
          }).then((res) => {
            this.sendShow = false;
            this.countDown();
            this.$message({
              type: "success",
              message: "验证码已发送到您的手机上!",
            });
          });
        }
      });
    },
    // 验证码倒计时
    countDown() {
      clearInterval(this.countInterval);
      this.countInterval = setInterval(() => {
        this.totalTime--;
        if (this.totalTime < 0) {
          clearInterval(this.countInterval);
          this.totalTime = 180;
          this.sendShow = true;
        }
      }, 1000);
    },
    // 登录
    submit(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          if(this.loginType == '2'){
            this.ruleForm.username = this.ruleForm.username.replace(
              /^\s+|\s+$/g,
              ""
            );
            this.$post(
              "/sys/admin/validation",
              {
                password: this.ruleForm.password.trim(),
                username: this.ruleForm.username,
              },
              3000,
              false
            ).then((ret) => {
              if (ret.data.enable) {
                this.dialogVisiblemobile = true;
                this.editForm.editmobile = AES.decrypt(ret.data.mobile);
              } else {
                this.$post(
                  "/login",
                  {
                    password: this.ruleForm.password,
                    username: this.ruleForm.username,
                    captchaKey: this.yzmInfo.key,
                    captchaValue: this.ruleForm.code,
                  },
                  3000,
                  false
                )
                  .then((ret1) => {
                    this.cacheUserData(ret1);
                  })
                  .catch(() => {
                    this.$store.dispatch("handleDelAll");
                    this.getYzm();
                  });
              }
            });
          }else if(this.loginType == '1'){
            this.$post(
              "/sms/login",
              {
                mobile: this.ruleForm.mobilePhone,
                smsCode: this.ruleForm.msgeCode,
              },
              3000,
              false
            )
            .then((ret1) => {
              this.cacheUserData(ret1);
            })
            .catch(() => {
              this.$store.dispatch("handleDelAll");
            });
          }
        }
      });
    },
    // 缓存登录数据
    cacheUserData(ret) {
      const datas = ret.data || {};
      //   console.log(datas);
      this.roleId = datas.principal.roleId;
      // 缓存参数
      this.$store.dispatch("handleToken", datas.token || "");
      this.$store.dispatch("handleUser", {
        compId: datas.principal.compId || "",
        userId: datas.userId || "",
        fullname: datas.principal.fullname || "",
        roleId: datas.principal.roleId || "",
        passwordExpire: datas.principal.passwordExpire,
        trainTypeIdRange: datas.principal.trainTypeIdRange,
        createTime: datas.principal.createTime,
        tempAccount: datas.principal.tempAccount,
        videoIsMark: datas.principal.videoIsMark,
        areaId:datas.principal.areaId || "",
      });
      if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
        this.$router.push("/web/operate/change");
      } else {
        this.getMenu();
      }
    },
    getMenu() {
      this.$post("/sys/per/menu/userId", {}, 3000, false)
        .then((res) => {
          const data = res.data || [];
          this.setDictionary().then(() => {
            let url = (data.find((i) => i.name == "首页") || {}).url || null;
            if (this.roleId != "2" && url) {
              this.$router.push(url);
            } else {
              this.$router.push({
                path: data[0].url ? data[0].url : data[1].url,
              });
            }
          });
          this.$store.dispatch("handleMenu", data || []);
        })
        .catch(() => {
          this.$store.dispatch("handleDelAll");
        });
    },
    // 数据字典
    setDictionary() {
      return new Promise((resolve, reject) => {
        this.$post("/sys/dictionary/all", {}, 3000, false).then((ret) => {
          const retData = ret.data || [];
          let dictionaryArr = [];
          let dictionaryJson = {};
          retData.forEach((item) => {
            if (!dictionaryArr.includes(item.dictKey)) {
              dictionaryArr.push(item.dictKey);
              dictionaryJson[item.dictKey] = {};
            }
            dictionaryJson[item.dictKey][item.dictCode] = item.dictValue;
          });
          this.$store.dispatch("handleDictionary", dictionaryJson);
          resolve();
        });
      });
    },
    // 协议
    jump(stu) {
      this.dialogVisible = true;
      if (stu == 1) {
        this.src = "https://anzhi.bjsrxx.com/banner/et-service-Pc.html";
        this.title = "用户服务协议";
      } else {
        this.src = "https://anzhi.bjsrxx.com/banner/et-privacy-Pc.html";
        this.title = "隐私政策";
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.src = "";
      this.title = "";
    },
    // 获取验证码
    getYzm() {
      this.$post("/sys/captcha/get").then((res) => {
        this.yzmInfo.imageBase64 = res.data.imageBase64;
        this.yzmInfo.key = res.data.key;
        this.ruleForm.code = "";
      });
    },
    clear(val) {
      const el = this.$refs["ruleForm"];
      el.clearValidate(val);
    },
    /**
     * 跳转到IP备案页面
     * @param
     */
    goToIp() {
      window.open("http://beian.miit.gov.cn");
    },
    checkbrowser() {
      if (navigator.userAgent.indexOf("Edge") >= 0) {
        //微软edge
        alert(
          "抱歉！暂时还无法兼容您当前的浏览器！为了能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      } else if (
        navigator.userAgent.indexOf("Chrome") >= 0 &&
        navigator.userAgent.indexOf("WebKit") >= 0
      ) {
        //谷歌内核
      } else if (navigator.userAgent.indexOf("AppleWebKit") >= 0) {
        //mac谷歌内核
      } else {
        alert(
          "抱歉！暂时还无法兼容您当前的浏览器！为了您能正常使用网址功能，推荐使用谷歌浏览器，QQ浏览器（极速模式），360浏览器（极速模式）"
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  background: url("../../assets/login-qiye-bg.png") center center no-repeat;
  background-size: cover;
  .login_1 {
    position: absolute;
    left: 60px;
    top: 60px;
    width: 128px;
    height: 38px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .login_2_wrap {
    display: flex;
    margin-bottom: calc(3% + 49px);
    box-shadow: 0px 0px 60px 0px rgba(189,208,227,0.16);
  }
  .login_2 {
    width: 470px;
    height: 470px;
    background-color: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 40px 60px;
    box-sizing: border-box;
      .wrap {
        padding: 25px;
        .tabs {
          width: 100%;
          .tab {
            text-align: center;
            width: 50%;
            position: relative;
            display: inline-block;
            font-size: 15px;
            margin-bottom: 30px;
            font-weight: 500;
            color: #333333;
            user-select: none;
            cursor: pointer;
          }
          .tab.active {
            color: #2878ff;
          }
          .tab.active::after {
            content: "";
            position: absolute;
            bottom: -10px;
            left: 50%;
            margin-left: -23.5px;
            width: 45px;
            height: 2px;
            background: #2878ff;
            border-radius: 1px;
          }
        }
        .loginForm {
          .onSubmit {
            width: 100%;
            margin-top: 10px;
          }
          /deep/ .el-input__suffix{
            line-height: 50px;
          }
          .yzm {
            border-bottom: 1px solid #f7f9fe;
            display: flex;
            // overflow: hidden;
            align-items: center;
            /deep/.el-form-item__content {
              display: flex;
              // overflow: hidden;
              align-items: center;
            }
            .loginput input {
              border: none;
            }
          }
        }
      }
      p {
        font-size: 11px;
        line-height: 1.8em;
        color: #666666;
      }
  }
  .login_3 {
    position: absolute;
    bottom: 20px;
    p {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
.login .login_2_bg {
  width: 470px;
  height: 470px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    height: 100%;
  }
}
.login /deep/ .el-form .el-input__prefix {
  font-size: 20px;
}
.login /deep/ .el-form .el-input__inner {
  border: none !important;
  height: 50px;
  border-bottom: 1px solid #f7f9fe !important;
}
/deep/ .el-dialog__body{
  text-align: left;
}
.loginFoot {
  position: absolute;
  left: 50%;
  margin-left: -490px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: #999999;
  width: 980px;
  justify-content: center;
  padding-bottom: 15px;
  line-height: 32px !important;
  font-size: 14px;
  .loginFoot_con{
    display: flex;
    justify-content: center;
  }
  span {
    font-size: 14px;
    font-family: PingFang SC;
  }
  .loginpadding {
    // padding: 20px 0;
    span {
      color: #999999;
      padding-left: 5px;
    }

    .el-link.el-link--default:hover {
      color: #fff;
    }
  }
}
</style>