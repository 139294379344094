<template>
  <div class="topToolbarContainer">
    <div class="top-wrap">
      <!-- logo -->
      <div class="logo">
        <img src="../assets/logoMongolia.png" alt=""/>
      </div>
      <!-- 自定义标题 -->
      <div class="title">{{ topTitle }}</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "topToolbarSimple",
    //通过父组件传入topTitle自定义属性，可在topbar中显示信息
    props: {
      topTitle: {
        type: String,
      },
    },
  };
</script>

<style lang="less" scoped>
  .topToolbarContainer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: white;
    width: 100%;
    height: 3.3125rem;
    .top-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      max-width:63.25rem;
      margin: 0 auto;
      .logo {
        cursor: pointer;
        overflow: hidden;
        img{
            display: block;
            width: 2rem;
            height: 2rem;
        }
        margin-right: .9375rem;
      }
      .title {
        font-size: 1.0625rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
  }
</style>