import { render, staticRenderFns } from "./LoginQiye.vue?vue&type=template&id=87a5861c&scoped=true"
import script from "./LoginQiye.vue?vue&type=script&lang=js"
export * from "./LoginQiye.vue?vue&type=script&lang=js"
import style0 from "./LoginQiye.vue?vue&type=style&index=0&id=87a5861c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87a5861c",
  null
  
)

export default component.exports