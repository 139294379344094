<template>
    <div class="login-page">
        <TopbarSP/>
        <!-- 登录区域 -->
        <div class="login-area">
            <el-carousel trigger="click" height="26.25rem">
                <el-carousel-item v-for="item in bannerList" :key="item.bannerId">
                    <div class="banner-img-wrap" style="width:100%;height:100%;">
                        <img class="banner-img" :src="item.imgUrl" alt="图片被外星人抓走了~~~">
                    </div>
                </el-carousel-item>
            </el-carousel>
            <div class="login-box">
                <div v-if="loginMethod===1" class="title">登录</div>
                <div v-else class="title">微信扫码登录</div>
                <div class="form">
                    <!-- <div v-if="loginMethod===1" class="qr-code">
                        <img @click="switchLogin" src="../../assets/humanResources/QRcode.png" alt="">
                        <div class="qr-code-tip">微信扫码快捷登录~</div>
                    </div>
                    <div v-else class="qr-code">
                        <img @click="switchLogin" src="../../assets/humanResources/pc.png" alt="">
                        <div class="qr-code-tip">验证码登录~</div>
                    </div> -->
                    <!-- 验证码登录 -->
                    <template v-if="loginMethod===1">
                        <el-form ref="ruleForm" :model="formData" :rules="rules">
                            <el-form-item prop="mobile">
                                <el-input size="small" clearable class="form-item" v-model="formData.mobile" placeholder="请输入手机号" @input="filterText('mobile')" @focus="clearValid('mobile')"></el-input>
                            </el-form-item>
                            <el-form-item prop="smsCode">
                                <div class="form-item">
                                    <el-input size="small" class="vcode-input" clearable v-model="formData.smsCode" @input="filterText('smsCode')" placeholder="请输入验证码" @focus="clearValid('smsCode')"></el-input>
                                    <div class="Vcode">
                                        <div class="line"></div>
                                        <div v-if="isCode" class="get-code" style="color:#C0C4CC">{{time+"s"}}</div>
                                        <div v-else class="get-code" @click="getVCode">获取验证码</div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <el-button class="form-item form-btn" @click="login">立即登录</el-button>
                        <div class="agree-wrap">
                            <el-checkbox v-model="agree"></el-checkbox>
                            <div class="agree">已阅读并同意<span @click="jump(1)">《用户服务协议》</span>和<span @click="jump(2)">《隐私政策》</span></div>
                        </div>
                        <div class="register">
                            <div>还没有账号?</div>
                            <div class="go-register" @click="register">免费注册</div>
                        </div>
                    </template>
                    <!-- 扫码登录 -->
                    <template v-else>
                        <div class="code-img-border">
                            <div class="code-img-wrap" id="login_container">
                                <!-- <img  src="../../assets/humanResources/QRcode_login.png" alt=""> -->
                            </div>
                        </div>
                        <div class="title-tips">
                            <div>请使用微信扫描二维码</div>
                            <div>授权登录</div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!-- 服务板块区域 -->
        <div class="service-sector">
            <div class="item" v-for="(item,index) in serviceList" :key="index">
                <div class="title">{{item.title}}</div>
                <div class="detail">{{item.detail}}</div>
                <div class="consulting">
                    <div  @click="consulting(item,index)">立即咨询</div>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <Footbar/>
    </div>
</template>

<script>
    import "../../utils/wx_login.js";
    import TopbarSP from "../../components/HumanResources/TopbarSP.vue";
    import Footbar from "../../components/HumanResources/Footbar.vue";
    export default {
        name:"HRLogin",
        components:{
            TopbarSP,
            Footbar
        },
        data(){
            // 验证手机号
            const validateMobile=(rule,value,callback)=>{
                const reg=/^1[3456789]\d{9}$/;
                if(!reg.test(value)){
                    callback("请输入正确的手机号");
                    return;
                }
                callback();
            }
            // 验证验证码
            const validateSmsCode=(rule,value,callback)=>{
                const reg=/^\d{6}$/;
                if(!reg.test(value)){
                    callback("请输入正确的验证码");
                    return;
                }
                callback();
            }
            return{
                // 登录方式 默认验证码登录
                loginMethod:1,
                // 表单数据
                formData:{
                    mobile:"", // 手机号
                    smsCode:"" // 验证码
                },
                // 轮播图数据
                bannerList:[],
                // 服务区域数据
                serviceList:[
                    {
                        title:"灵活用工服务",
                        detail:"为企业提供共享用工，众包，资金结算，税务代征等全流程合规服务。帮助人才与企业合法合规共享对接。"
                    },
                    {
                        title:"职业中介服务",
                        detail:"帮助企业解决招聘难题，通过自有人才简历库，快速、精准实现招聘服务，促进人力资源供需匹配对接。"
                    },
                    {
                        title:"人力资源咨询",
                        detail:"提供人力资源规划、劳动关系管理、薪酬绩效、股权设计、劳动相关法律等方面的咨询，劳动合同、规章制度、保密协议等合规性审核。"
                    },
                    {
                        title:"企业内训",
                        detail:"汇集海量优质课程，提供数字化培训工具，简化培训流程，提升员工业务技能，推动企业和员工不断进步。"
                    },
                    {
                        title:"社保与税收筹划",
                        detail:"为企业提供社保代缴和税收筹划咨询、税收优惠等服务，制定合理节税筹划方案，减轻税收负担。"
                    },
                    {
                        title:"企业残保金服务",
                        detail:"提供残疾人保障金申报减免服务，帮助残疾人提升职业技能，合规残疾人用工、帮扶残疾人就业。"
                    },
                ],
                // 表单验证规则
                rules:{
                    mobile:[
                        {required:true,message:"请输入手机号",trigger:"blur"},
                        {validator:validateMobile,trigger:"blur"}
                    ],
                    smsCode:[
                        {required:true,message:"请输入验证码",trigger:"blur"},
                        {validator:validateSmsCode,trigger:"blur"}
                    ]
                },
                // 是否同意协议表单
                agree:false,
                // 是否发送验证码
                isCode:false,
                // 重新发送定时器
                timer:null,
                // 重新发送计时
                time:180
            }
        },
        methods:{
            // 初始化数据
            initData(){
                this.getBannerList();
            },
            // 切换验证码扫码登录
            switchLogin(){
                const {loginMethod}=this;
                if(loginMethod===1){
                    this.loginMethod=2;
                    // this.$nextTick(()=>{
                    //     const obj = new WxLogin({
                    //         self_redirect:true,
                    //         id:"login_container", 
                    //         appid: "wxa00d8eef7e369cee", 
                    //         scope: "snsapi_login", 
                    //         redirect_uri: "https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html",
                    //         state: "",
                    //         style: "white",
                    //         href: "./wx_login.css"
                    //     });
                    //     console.log("微信登录对象",obj);
                    // });
                }else{
                    this.loginMethod=1;
                }
            },
            // 发送短信验证码
            getVCode(){
                const {formData}=this;
                this.$refs.ruleForm.validateField("mobile",valid=>{
                    if(!valid){
                        // 倒计时
                        clearInterval(this.timer);
                        this.isCode=true;
                        this.timer=setInterval(()=>{
                            this.time=this.time-1;
                            if(this.time<0){
                                this.isCode=false;
                                this.time=180;
                                clearInterval(this.timer);
                            }
                        },1000);
                        this.$post("/sys/sms/login/captcha",{
                            mobile:formData.mobile
                        }).then(()=>{
                            this.$message.success("验证码已发送至您的手机，请注意查收");
                        }).catch(()=>{
                            this.isCode=false;
                            this.time=180;
                            clearInterval(this.timer);
                        });
                    }
                });
            },
            // 获取轮播图数据
            getBannerList(){
                this.$post("/hr/open/pc/banner/list",{}).then((res)=>{
                    this.bannerList=res.data||[];
                }).catch((e)=>{
                    console.error("获取轮播图数据出错",e);
                });
            },
            // 点击服务区域的咨询
            consulting(item,index){
                // 数据采集
                this.$post("/biz/v1/buried/point/collect",{
                    systemCode:"HR",
                    terminalCode:"PC",
                    moduleCode:"HOME",
                    positionCode:"FIXED_AREA",
                    eventCode:"CLICK",
                    topNum:index+1,
                    contentId:index+1,
                    contentName:item.title
                },3000,false,5);
                // 打开客服
                window.open("https://108736.kefu.easemob.com/webim/im.html?configId=301c075f-fa61-4fee-8968-771a086d1390&language=zh-CN");
            },
            // 替换掉不规范的输入
            filterText(key){
                const {formData}=this;
                const reg=/\D*/ig;
                formData[key]=formData[key].replace(reg,"");
            },
            // 清除某一项表单的校验结果
            clearValid(key){
                this.$refs.ruleForm.clearValidate(key);
            },
            // 点击登录
            login(){
                const {formData}=this;
                this.$refs.ruleForm.validate(valid=>{
                    if(valid){
                        if(!this.agree){
                            this.$message.error("请阅读并同意用户服务协议和隐私政策！");
                            return;
                        }
                        // 发起登录请求
                        this.$post("/hr/sms/login",formData).then(res=>{
                            // 缓存参数(用户数据 token之类的)
                            this.cacheUserData(res);
                        }).catch(()=>{
                            this.$store.dispatch("handleDelAll");
                        });
                    }
                });
            },
            // 缓存用户数据
            cacheUserData(res){
                this.$store.dispatch("handleToken", res?.data?.token || "");
                this.$store.dispatch("handleUser",{
                    compId: res?.data?.principal?.compId || "", // 机构id
                    userId: res?.data?.userId || "", // 用户di
                    fullname: res?.data?.principal?.fullname || "",// 用户名
                    roleId: res?.data?.principal?.roleId || "",// 角色id
                    passwordExpire: res?.data?.principal?.passwordExpire,// 密码是否密文
                    trainTypeIdRange: res?.data?.principal?.trainTypeIdRange, 
                    createTime: res?.data?.principal?.createTime,// 创建时间
                    tempAccount: res?.data?.principal?.tempAccount,
                    videoIsMark: res?.data?.principal?.videoIsMark,
                    areaId: res?.data?.principal?.areaId || "",//区划id
                    principal:res?.data?.principal||{} // 用户全部的信息
                });
                if (JSON.parse(sessionStorage.getItem("userJson")).passwordExpire) {
                    this.$router.push("/web/operate/change");
                } else {
                    this.getMenu();
                }
            },
            // 获取左侧路由
            getMenu() {
                this.$post("/sys/per/menu/userId", {}, 3000, false).then((res) => {
                    const data = res.data || [];
                    this.setDictionary().then(() => {
                        // let url = (data.find((i) => i.name == "首页") || {}).url || null;
                        // if (this.roleId != "2" && url) {
                        //     this.$router.push(url);
                        // } else {
                        //     this.$router.push({
                        //         path: data[0].url ? data[0].url : data[1].url,
                        //     });
                        // }
                        // 
                        // 检测是否需要创建公司信息
                        this.checkNeedCreateCompany();
                    }); 
                    this.$store.dispatch("handleMenu", data || []);
                }).catch(() => {
                    this.$store.dispatch("handleDelAll");
                });
            },
            // 数据字典
            setDictionary() {
                return new Promise((resolve) => {
                    this.$post("/sys/dictionary/all", {}, 3000, false).then((ret) => {
                        const retData = ret.data || [];
                        let dictionaryArr = [];
                        let dictionaryJson = {};
                        retData.forEach((item) => {
                            if (!dictionaryArr.includes(item.dictKey)) {
                                dictionaryArr.push(item.dictKey);
                                dictionaryJson[item.dictKey] = {};
                            }
                            dictionaryJson[item.dictKey][item.dictCode] = item.dictValue;
                        });
                        this.$store.dispatch("handleDictionary", dictionaryJson);
                        resolve();
                    });
                });
            },
            // 检测是否需要创建公司信息
            checkNeedCreateCompany(){
                this.$post("/hr/checkNeedCreateCompany",{}).then(res=>{
                    if(res.data===true){
                        this.$router.push({
                            path:"/azhr/humanResources/enterpriseInfo"
                        });
                    }else{
                        this.$router.push({
                            path:"/azhr/home"
                        });
                    }
                });
            },
            // 注册
            register(){
                this.$router.push("/azhr/humanResources/register");
            },
            // 跳转隐私协议和服务
            jump(type){
                // 服务
                if(type===1){
                    window.open("https://anzhi.bjsrxx.com/banner/anzhi-humanResources-sa.html");
                }else{
                    // 隐私
                    window.open("https://anzhi.bjsrxx.com/banner/anzhi-humanResources-pa.html");
                }
            }
        },
        created(){
            this.initData();
        },
        beforeDestroy(){
            clearInterval(this.timer);
        }
    }
</script>

<style scoped lang="less">
    // 滚动条整体部分
    ::-webkit-scrollbar {
        width:10px;
        border-radius: 0px;
    }
    // 内层轨道 
    ::-webkit-scrollbar-track-piece{
        border-radius: 0px;
        background: white;
    }
    // 滚的条
    ::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #e7e7e7;
    }
    .login-page{
        height: 100vh;
        overflow-y: auto;
        // 登录区域
        .login-area{
            width: 100%;
            height: 26.25rem;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            // 轮播图
            .banner-img-wrap{
                height: 100%;
                width: 100%;
                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .login-box{
                height: 21.5rem;
                width: 21rem;
                background-color: #FFFFFF;
                position: absolute;
                right: 19%;
                top: 2.6rem;
                box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.08);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 10;
                // 二维码
                .qr-code{
                    position: absolute;
                    width: 3.125rem;
                    height: 3.125rem;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    .qr-code-tip{
                        position: absolute;
                        right: 1.875rem;
                        top: .5rem;
                        background: #333333;
                        padding: .375rem .6875rem;
                        color: #FFFFFF;
                        font-size: .6875rem;
                        border-radius: 4px;
                        display: none;
                        white-space: nowrap;
                        &::after{
                            content: "";
                            height: 4px;
                            width: 4px;
                            display: block;
                            position: absolute;
                            background: #333333;
                            transform: rotate(45deg);
                            right: -2px;
                            top:12px;
                        }
                    }
                    img{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    &:hover .qr-code-tip{
                        display: block;
                    }
                }
                .title{
                    font-size: .875rem;
                    font-weight: 600;
                    color: #333333;
                    margin-top: 3.375rem;
                }
                // 表单区域
                .form{
                    padding: 0 1.875rem;
                    box-sizing: border-box;
                    /deep/.el-form{
                        .el-form-item{
                            margin: 0;
                            .el-form-item__content{
                                line-height: 0 !important;
                            }
                        }
                    }
                    // 输入框
                    /deep/.el-input{
                        .el-input__inner{
                            height: 2.125rem !important;
                        }
                    }
                    .form-item{
                        margin-top: 1.125rem;
                        position: relative;
                        // 验证码的输入框
                        /deep/.vcode-input{
                            .el-input__inner{
                                padding-right: 7.5rem;
                            }
                            .el-input__suffix{
                                right: 6rem;
                            }
                        }
                        .Vcode{
                            display: flex;
                            align-items: center;
                            height: 100%;
                            position: absolute;
                            right: 0;
                            top: 0;
                            cursor: pointer;
                            .line{
                                width: 1px;
                                height: 1.25rem;
                                background: #DDDDDD;
                            }
                            .get-code{
                                font-size: .875rem;
                                color: #2878FF;
                                width: 90px;
                                text-align: center;
                            }
                        }
                    }
                    // 登录按钮
                    .form-btn{
                        margin-top: 1.25rem;
                        width: 100%;
                        background: #2878FF;
                        border-radius: 2px;
                        color: white;
                        font-size: .875rem;
                        border: none;
                        height: 2.125rem !important;
                        transition: 0.3s;
                        &:hover{
                            background: #005cfb;
                        }
                        &:active{
                            background: #5d98fd;
                        }
                    }
                    // 同意协议
                    .agree-wrap{
                        display: flex;
                        margin-top: 0.875rem;
                        word-break: break-all;
                        .el-checkbox{
                            margin-right: 4px;
                            margin-top: 1px;
                        }
                        .agree{
                            font-size: .75rem;
                            font-weight: 400;
                            color: #999999;
                        }
                        span{
                            cursor: pointer;
                            color: #2878FF;
                        }
                    }
                    // 没有账号？去注册
                    .register{
                        margin-top: .9375rem;
                        font-size: .6875rem;
                        font-weight: 400;
                        color: #999999;
                        display: flex;
                        .go-register{
                            margin-left: 7px;
                            color: #2878FF;
                            cursor: pointer;
                        }
                    }
                    // 微信扫码登录
                    .code-img-border{
                        width: 10rem;
                        height: 10rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #EEEEEE;
                        margin-top: 12px;
                        .code-img-wrap{
                            width: 9rem;
                            height: 9rem;
                            overflow: hidden;
                            // img{
                            //     width: 100%;
                            //     height: 100%;
                            // }
                        }
                    }
                    .title-tips{
                        margin-top: 12px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: 1rem;
                        color: #666666;
                    }
                }
            }
        }
        // 服务板块区域
        .service-sector{
            width: 75rem;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            padding: 3rem 0;
            justify-content: space-between;
            .item{
                width: 23.75rem;
                height: 11.5625rem;
                background-color: white;
                box-shadow: 0px 0px 20px 0px rgba(229,235,251,0.6);
                border-radius: 4px;
                transition: 0.3s;
                margin-top: 34px;
                padding: 1.875rem 2.5rem;
                &:nth-child(1){
                    margin-top: 0;
                }
                &:nth-child(2){
                    margin-top: 0;
                }
                &:nth-child(3){
                    margin-top: 0;
                }
                // 标题
                .title{
                    font-size: 1.125rem;
                    font-weight: 600;
                    color: #333333;
                }
                .detail{
                    font-size: .875rem;
                    font-weight: 400;
                    color: #666666;
                    margin-top: .75rem;
                }
                .consulting{
                    display: flex;
                    align-items: center;
                    font-size: .75rem;
                    color: #2878FF;
                    margin-top: 1rem;
                    cursor: pointer;
                }
                &:hover{
                    background-color: #669BF5;
                }
                &:hover .title{
                    color: white;
                }
                &:hover .detail{
                    color: white;
                }
                &:hover .consulting{
                    color: white;
                }
            }
        }
    }
</style>